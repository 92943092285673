// to be completed

import moment from 'moment'
import { handleQueryResolve } from '../utils'

export default function (fromDate, toDate) {
  // console.log(fromDate + ' ' + toDate);

  const dateFormat = 'DD/MM/YYYY'
  const fromDateStr = moment(fromDate).format(dateFormat)
  const toDateStr = moment(toDate).format(dateFormat)

  // const fromDate = '03/12/2019';
  // const toDate = '04/12/2019';

  // Fixme: For some reason this doesn't seem to work and give date conversion error
  // Need to figure out how to make this work instead of inserting string value
  // directly into query
  const attributes = []
  const where = []
  if (fromDate) {
    attributes.push({
      param: 'fromDate',
      type: 'sql.VarChar(60)',
      value: 'CONVERT(DATE,\'20191203\',112)',
    })

    attributes.push({
      param: 'toDate',
      type: 'sql.VarChar(60)',
      value: 'CONVERT(DATE,\'20191204\',112)',
    })

    where.push('CONVERT(DATE,Rx.RxDate) BETWEEN @fromDate')
    where.push('@toDate')
  }

  const queryStr = `
  SELECT
  rx.ID,
  MixID,
  rx.DocID,
  DrgMix.Description,
  OrigRxNum,
  RxNum,
  FillDate,
  DispQty,
  AAC,
  Cost,
  Markup,
  Fee,
  MixTime,
  MixFee,
  Status,
  DocAddressLoc,
  doctor.FirstName as DoctorFirstName,
  doctor.LastName as DoctorLastName
FROM
  pharmacy.dbo.Rx
  INNER JOIN pharmacy.dbo.DrgMix ON DrgMix.ID = rx.MixID
  INNER JOIN pharmacy.dbo.Doc as doctor ON doctor.ID = rx.DocID
  INNER JOIN pharmacy.dbo.DocAdd as DoctorAddress ON doctor.ID = DoctorAddress.DocID
  AND DoctorAddress.DefaultLocation = 1
  LEFT JOIN pharmacy.dbo.DocPhone as DoctorFax ON doctor.ID = DoctorFax.DocID
  AND DoctorFax.Type = '2'
  LEFT JOIN pharmacy.dbo.DocPhone as DoctorPhone ON doctor.ID = DoctorPhone.DocID
  AND DoctorPhone.Type = '1'
WHERE
  MixID >= 1 
  AND Rx.FillDate > DATEADD(day, -1, GetDate())
ORDER BY
  FillDate DESC
      `

  // console.log(queryStr);
  return this.query(queryStr, attributes).then(handleQueryResolve)
}
